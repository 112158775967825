import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../store/common-api/axios';
import EndPoint from '../../api/endpoint';
const initialState = {
    questionnaire: [],
    loading: false,
    caseID: "",
    lastSavedSectionID: null

};

// GETTING ALL QUESTIONNAIRE LIST
export const getQuestionnairesListAction = createAsyncThunk(
    "questionnairesModule/getQuestionnairesListAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.QUESTIONAIRRE_LIST}?case_id=${value.case_id}&client_id=${value.client_id}&derivates_id=${value.derivates_id}`;
        try {
            const response = await axiosRequest.get(apiUrl);

            return response;
        } catch (error: any) {
            return error;

        }
    }
);

// GETTING ALL CASES BY CLIENT
export const getCaseOfClientBasedOnClientIDAction = createAsyncThunk(
    "knowledgebaseFormsModule/getCaseOfClientBasedOnClientIDAction",
    async () => {
        try {
            const response = await axiosRequest.get(`${EndPoint.CLIENT_CASES}`);
            return response;
        } catch (error: any) {

            return error;
        }
    }
);

// GETTING ALL APPLICANT BASED ON CASE
export const getApplicantOfClientBasedOnClientIDAction = createAsyncThunk(
    "knowledgebaseFormsModule/getApplicantOfClientBasedOnClientIDAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.CLIENT_APPLICANT_LIST}?case_id=${value.case_id}`);
            return response;
        } catch (error: any) {

            return error;
        }
    }
);

// GETTING ALL QUESTIONNNAIRE 
export const viewClientQuestionnaaireAction = createAsyncThunk(
    "knowledgebaseFormsModule/viewClientQuestionnaaireAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.VIEW_QUESTIONNNAIRE}?case_id=${value.case_id}&questionnaire_id=${value.questionnaire_id}`);
            return response;
        } catch (error: any) {

            return error;
        }
    }
);

// SUBMITTING THE QUESTINNNAIRE SECTION OR COMPLETE SAVE
export const submitCaseQuestionnnaireAction = createAsyncThunk(
    "teamsModule/submitCaseQuestionnnaireAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.put(`${EndPoint.SUBMIT_QUESTIONNAIRE}`, value)
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

// MARKING SECTION AS NOT APPLICABLE
export const notApplicableCaseQuestionnnaireAction = createAsyncThunk(
    "teamsModule/notApplicableCaseQuestionnnaireAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.put(`${EndPoint.NOTAPPLICABLE_QUESTIONNAIRE}`, value)
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

const questionairreSlice = createSlice({
    name: 'Questoinairre Management',
    initialState: initialState,
    reducers: {
        setCaseID(state, actions) {
            state.caseID = actions.payload.caseID;
        },
        startLoader(state, actions) {
            state.loading = true;
        },
        stopLoader(state, actions) {
            state.loading = false;
        },
        saveLastSection(state, action) {
            state.lastSavedSectionID = action.payload.lastSavedSectionID
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuestionnairesListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getQuestionnairesListAction.fulfilled, (state, action) => {
                state.loading = false;
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.questionnaire = data;
            })
            .addCase(getQuestionnairesListAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getCaseOfClientBasedOnClientIDAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCaseOfClientBasedOnClientIDAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getCaseOfClientBasedOnClientIDAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getApplicantOfClientBasedOnClientIDAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getApplicantOfClientBasedOnClientIDAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getApplicantOfClientBasedOnClientIDAction.rejected, (state, action) => {
                state.loading = false;
            }).addCase(viewClientQuestionnaaireAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(viewClientQuestionnaaireAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(viewClientQuestionnaaireAction.rejected, (state, action) => {
                state.loading = false;
            })

    }
});

export const questionairreActions = questionairreSlice.actions;

export default questionairreSlice.reducer;