import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { REACT_TABLE_ORDER } from "../../../constants/global";
import CustomPaginator from "./CustomPaginator";
import "./datagrid.scss";

export interface IDataGridCommon {
  columns: any;
  data: any;
  onPageChange?: any;
  onSort?: any;
  currentPage?: any;
  tableRecords?: any;
  HideTablePaginator?: Boolean;
  reorderableRows?: any,
  onRowReorder?: any,
  stripedRows?: any
}
interface ColumnInfo {
  field: string;
  order: number;
}

//Custom component for Data Grid used at all places
const DataGridCommon: React.FC<IDataGridCommon> = ({
  columns,
  data,
  onSort,
  onPageChange,
  tableRecords,
  HideTablePaginator,
  reorderableRows,
  onRowReorder,
  stripedRows
}) => {
  const [sortOrder, setSortOrder] = useState(REACT_TABLE_ORDER);
  const [sortField, setSortField] = useState("");
  const [first, setFirst] = useState(0);
  const [multiSortMeta, setMultiSortMeta] = useState<[]>([]);

  const handlePageChange = (newPage: number, rows: any) => {
    onPageChange(newPage, rows); // Call the prop function to update currentPage in the parent component
  };
  //Sort List using header
  const handleSort = (event: any) => {
    setMultiSortMeta(event.multiSortMeta);
    setFirst(0);
    event.multiSortMeta.forEach((columnInfo: ColumnInfo) => {
      const { field, order } = columnInfo;
      const sortOrder = order !== 1 ? "DESC" : "ASC";
      setSortOrder(sortOrder);
      setSortField(field);
      // Call onSort with the updated sortOrder and sortField values
      onSort(sortOrder, field);
    });
  };

  /**
   * Will Add Class if data will have property named isAlreadyAdded
  **/
  const getRowClassName = (rowData: any) => {
    if (rowData.isAlreadyAdded === true) {
      return "bg-primary-subtle";
    } else {
      return "";
    }
  };

  return (
    <div className="d-flex dataGridMain flex-column h-100 overflow-auto w-100">
      <DataTable
        value={data}
        stripedRows={stripedRows ?? true}
        onSort={handleSort}
        sortMode="multiple"
        multiSortMeta={multiSortMeta}
        sortIcon={
          sortOrder === "DESC" ? (
            <FiArrowDown className="p-sortable-column-icon" size={17} />
          ) : (
            <FiArrowUp className="p-sortable-column-icon" size={17} />
          )
        }
        className="flex-grow-1 h-auto overflow-auto"
        scrollable={true}
        scrollHeight="flex"
        reorderableRows={reorderableRows}
        onRowReorder={onRowReorder}
        rowClassName={getRowClassName}
      >
        {columns.map((col: any) => (
          <Column
            key={col.field}
            sortable={col.sortable}
            field={col.field}
            header={col.header}
            headerClassName={`fs-15 ${col.headerClassName}`}
            style={{ width: col.width, minWidth: col.width }}
            body={col.body}
            bodyClassName={col.bodyClassName}
            rowReorder={col.rowReorder}
          />
        ))}
      </DataTable>

      {/* Custom Pagination */}
      {HideTablePaginator ? ''
        : <CustomPaginator
          data={data}
          onPageChange={handlePageChange}
          tableRecords={tableRecords}
        />
      }
    </div>
  );
};

export default DataGridCommon;
