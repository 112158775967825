import moment from "moment";
import { FC } from "react";
import { Modal, Stack } from "react-bootstrap";
import { FaLock } from "react-icons/fa";

interface IViewNotesModal {
  show?: boolean;
  handleClose?: any;
  notesData?: any
}

/**
 * View Notes Modal Component
 * @date 1/3/2024 - 3:42:20 PM
 * @param {{ rowData: any; show: any; handleClose: any; }} {
  rowData,
  show,
  handleClose,
}
 * @returns {*}
 */

const ViewNotesModal: FC<IViewNotesModal> = ({
  show,
  handleClose,
  notesData
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered={true}
      scrollable={true}
      size="lg"
      className="theme-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title as="div" className="flex-fill pe-3">
          <Stack
            direction="horizontal"
            gap={2}
          >
            <h6 className="fw-semibold mb-0 me-auto">Notes</h6>
            <div className="small lh-sm">
              <span className="fw-semibold">Updated on: </span> {notesData?.updated_at_without_timezone}
            </div>
          </Stack>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="pb-3">
        <div className="theme-modal-form-content theme-lh-lg small">
          <p dangerouslySetInnerHTML={{ __html: notesData?.note_content }} /></div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewNotesModal;