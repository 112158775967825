import { ThunkDispatch } from '@reduxjs/toolkit';
import CryptoJS from 'crypto-js';
import { useRef } from 'react';
import { Breadcrumb, Button, Card, Col, Form, Row, Stack } from 'react-bootstrap';
import { IoIosLock } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CommonFormikComponent from '../../components/common/CommonFormikComponent';
import ThemeInputPassword from '../../components/common/InputPassword';
import Loader from '../../components/common/Loader';
import appConfig from '../../helpers/config';
import { baseRoutes } from '../../routes/base-routes';
import { persistor } from '../../store';
import { changePasswordAction, logoutAction } from '../../store/reducer/auth';
import { ChangePasswordSchema } from '../../validations/changePassword';

const ChangePassword = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const isLoading = useSelector((state: any) => state.auth.loading);
    const formRef: any = useRef();
    const parameter = useParams();
    const navigate = useNavigate();

    // Initial Values
    const initialValues: Record<string, any> = {
        current_password: '',
        new_password: '',
        confirm_password: '',
    };

    const handleLogout = async () => {
        dispatch(logoutAction()).then(async (data) => {
            await persistor.purge();
            navigate(`${appConfig.basePath}/login`);
        });
    }

    // Handle Submit
    const handleSubmit = (values: Record<string, any>, actions: any) => {
        actions.setSubmitting(false);
        const encryptionKey = appConfig.passwordEncryptionKey;
        // Encrypt
        let cipherCurrentPassword = CryptoJS.AES.encrypt(values.current_password, encryptionKey).toString()
        let cipherNewPassword = CryptoJS.AES.encrypt(values.new_password, encryptionKey).toString()
        const payloadData = { token: parameter?.token, current_password: cipherCurrentPassword, new_password: cipherNewPassword };
        dispatch(changePasswordAction(payloadData)).then((data: any) => {
            formRef.current.setFieldValue("current_password", "");
            formRef.current.setFieldValue("new_password", "");
            formRef.current.setFieldValue("confirm_password", "");
            formRef.current.setTouched({
                current_password: false,
                new_password: false,
                confirm_password: false,
            })
            if (data?.payload?.statusCode === 200) {
                console.log('data?.payload?.statusCode', data?.payload?.statusCode)
                handleLogout();
            }
        })
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="d-flex flex-column pageContainer p-3 h-100 overflow-y-scroll">
                <div className="contentHeader p-1">
                    <Stack
                        direction="horizontal"
                        gap={2}
                        className="flex-wrap justify-content-between pb-2"
                    >
                        <h1 className="fw-semibold h4 mb-0">Change Password</h1>
                        <Breadcrumb className="fw-semibold">
                            <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
                        </Breadcrumb>
                    </Stack>
                </div>
                <div className="flex-grow-1 pageContent position-relative pt-4">
                    <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
                        <div className="theme-card-header px-1">
                            <Stack
                                direction="horizontal"
                                gap={3}
                                className="flex-wrap px-3 pt-3 pb-2 align-items-start"
                            >
                                <h5 className="mb-0 position-relative fw-normal me-auto">
                                    <div className="align-items-center bg-secondary d-inline-flex h-60 justify-content-center position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                        <IoIosLock size={32} />
                                    </div>
                                    Change Password
                                </h5>
                            </Stack>
                        </div>

                        <div className="flex-grow-1 d-flex flex-column p-3">
                            <div className="p-1 h-100 theme-from">
                                <CommonFormikComponent
                                    validationSchema={ChangePasswordSchema}
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                    innerRef={formRef}
                                >
                                    {(formikProps) => (
                                        <>
                                            <div className="d-flex flex-column h-100">
                                                <Row>
                                                    <Col sm={8} md={6} lg={4} xxl={3}>
                                                        <Form.Group className="mb-3">
                                                            <ThemeInputPassword
                                                                toggleMask={true}
                                                                controlId="current_password"
                                                                label='Current Password*'
                                                                handleBlur={formikProps.handleBlur}
                                                                handleChange={formikProps.handleChange}
                                                                value={formikProps.values.current_password}
                                                                touched={formikProps.touched.current_password}
                                                                errorsField={formikProps.errors.current_password}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <ThemeInputPassword
                                                                toggleMask={true}
                                                                controlId="new_password"
                                                                label='New Password*'
                                                                handleBlur={formikProps.handleBlur}
                                                                handleChange={formikProps.handleChange}
                                                                value={formikProps.values.new_password}
                                                                touched={formikProps.touched.new_password}
                                                                errorsField={formikProps.errors.new_password}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-4 pb-1">
                                                            <ThemeInputPassword
                                                                toggleMask={true}
                                                                controlId="confirm_password"
                                                                label='Confirm New Password*'
                                                                handleBlur={formikProps.handleBlur}
                                                                handleChange={formikProps.handleChange}
                                                                value={formikProps.values.confirm_password}
                                                                touched={formikProps.touched.confirm_password}
                                                                errorsField={formikProps.errors.confirm_password}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <div className="theme-from-footer mt-auto border-top px-3 pt-3">
                                                    <Stack
                                                        direction="horizontal"
                                                        gap={3}
                                                        className="justify-content-end px-1"
                                                    >
                                                        <Link
                                                            to={baseRoutes.dashboard.path}
                                                            className="btn btn-outline-primary"
                                                        >
                                                            Cancel
                                                        </Link>
                                                        <Button type="submit">Submit</Button>
                                                    </Stack>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </CommonFormikComponent>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default ChangePassword