import { ThunkDispatch } from "@reduxjs/toolkit";
import { useEffect, useRef } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { GoArrowLeft } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo-white.svg";
import ResetPasswordBanner from "../../assets/images/reset-password-banner.jpg";
import CommonFormikComponent from '../../components/common/CommonFormikComponent';
import ThemeInputPassword from "../../components/common/InputPassword";
import Loader from "../../components/common/Loader";
import appConfig from "../../helpers/config";
import { baseRoutes } from "../../routes/base-routes";
import { OTPAction } from "../../store/reducer/auth";
import { OTPValidationSchema } from "../../validations/auth-schema/otp-authentication";
import Timer from "./Timer";
export const OTPAuthenticate = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const isLoading = useSelector((state: any) => state.auth.loading);
    const location = useLocation();
    const { email } = location.state || {}; // Destructure email from state
    
    const formRef:any = useRef();
    // Initial Values
    const initialValues: Record<string, any> = {
        otp: '',
    };
    const navigate = useNavigate();

    useEffect(() => {
        if(!email) {
            navigate(baseRoutes.login.path);
        }
    }, [email]);

    const handleSubmit = (values: Record<string, any>, actions: any) => {
        actions.setSubmitting(false);
        const payloadData = {email, code: values.otp};
        dispatch(OTPAction(payloadData)).then((res)=>{
            if(res?.payload?.statusCode == 200){
				navigate(`${appConfig.basePath}/dashboard`);
			}
            // formRef.current.setFieldValue("otp", "");
            // formRef.current.setTouched({
            //     otp: false,
            // })
            
        })
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <Card className="border-0 overflow-auto min-vh-100 w-100 text-center z-1 py-4 px-3 p-md-5 rounded-0">
                <div className="theme-auth-header pt-2 pb-4 w-75 mx-auto">
                    <Link to="/" className="d-inline-block">
                        <Image src={Logo} alt="Logo" width={330} height={60} fluid />
                    </Link>
                </div>
                <Row className="justify-content-center g-0 mt-md-3">
                    <Col xs={12}>
                        <Card className="px-4 pb-4 px-md-5 pb-md-5 text-start rounded-3 border-0 mx-auto max-w-450">
                            <h1 className="fs-4 fw-semibold my-2 mt-md-4 pt-3 pt-md-4 text-center text-primary">2 Step Verification</h1>
                            <p className="text-center mb-4">Please enter your One Time Code shared on your registered&nbsp;email.</p>
                            <CommonFormikComponent
                                validationSchema={OTPValidationSchema}
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                innerRef={formRef}
                            >
                                {(formikProps) => (
                                    <>
                                        <Form.Group className="mb-3">
                                            <ThemeInputPassword
                                                inputClassName="theme-inputtext-lg"
                                                toggleMask={true}
                                                controlId="otp"
                                                label='Enter One Time Code*'
                                                handleBlur={formikProps.handleBlur}
                                                handleChange={formikProps.handleChange}
                                                value={formikProps.values.otp}
                                                touched={formikProps.touched.otp}
                                                errorsField={formikProps.errors.otp}
                                            />
                                        </Form.Group>
                                        <div>
                                            <Timer email={email} />
                                        </div>
                                        <Button className="w-100 btn-lg mt-2 fw-normal" type="submit">
                                            Submit
                                        </Button>
                                    </>
                                )}
                            </CommonFormikComponent>
                        </Card>
                        <div className="my-4 py-1">
                            <div onClick={() => {localStorage.removeItem("timer"); navigate(baseRoutes.login.pathName); }} >
                                <Link to={baseRoutes.login.path} className="d-inline-flex align-items-center gap-2 text-decoration-underline small text-white">
                                    <GoArrowLeft size={20} />
                                    <span>{baseRoutes.login.pathName}</span>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Card.ImgOverlay className="p-0 pe-none user-select-none z-n1 rounded-0">
                    <Card.Img
                        src={ResetPasswordBanner}
                        alt="Reset Password Banner"
                        className="w-100 h-100 object-fit-cover rounded-0"
                    />
                </Card.ImgOverlay>
            </Card>
        </>
    );
};
