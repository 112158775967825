import { Editor } from "primereact/editor";
import Quill from "quill";
import { FC, useEffect } from "react";
import Form from "react-bootstrap/Form";
import './editor.scss';
interface TextEditorProps {
  controlId: string;
  data: string;
  handleChange: any;
  label?: string;
  inputClassName?: any;
  editorHeight?: string;
}

const TextEditor: FC<TextEditorProps> = ({
  controlId,
  data,
  handleChange,
  label,
  inputClassName,
  editorHeight,
}) => {

  useEffect(() => {
    const Font = Quill.import('formats/font');
    Font.whitelist = [
      "default",
      "arial",
      "calibri",
      "poppins",
      "raleway",
      "roboto-slab"
    ];
    Quill.register(Font, true);
  }, []);

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <select
          className="ql-font"
          aria-label="ql-font"
          defaultValue="default"
        >
          <option value="default">Default</option>
          <option value="arial">Arial</option>
          <option value="calibri">Calibri</option>
          <option value="poppins">Poppins</option>
          <option value="raleway">Raleway</option>
          <option value="roboto-slab">Roboto Slab</option>
        </select>

        <select className="ql-size me-3" aria-label="ql-size" />

        <select className="ql-align" aria-label="ql-align" />
        <button className="ql-bold" aria-label="Bold" />
        <button className="ql-italic" aria-label="Italic" />
        <button className="ql-underline" aria-label="Underline" />
        <button className="ql-strike me-3" aria-label="ql-strike" />

        <select className="ql-color" aria-label="ql-color" />
        <select className="ql-background me-3" aria-label="ql-background" />

        <button className="ql-script" value="sub" aria-label="ql-sub" />
        <button
          className="ql-script me-3"
          value="super"
          aria-label="ql-super"
        />

        <button className="ql-blockquote" aria-label="ql-blockquote" />
        <button className="ql-code-block me-3" aria-label="ql-code-block" />

        <button className="ql-list" value="ordered" aria-label="ql-ordered" />
        <button className="ql-list" value="bullet" aria-label="ql-bullet" />
        <button className="ql-indent" value="-1" aria-label="ql-indent -1" />
        <button
          className="ql-indent me-3"
          value="+1"
          aria-label="ql-indent +1"
        />

        <button className="ql-link" aria-label="ql-link" />
        <button className="ql-image" aria-label="ql-image" />
        <button className="ql-clean" aria-label="ql-clean" />
      </span>
    );
  };
  const header = renderHeader();
  return (
    <Form.Group controlId={controlId} className="theme-editor-cover">
      {label ? (
        <Form.Label
          className={`mb-1 d-flex ${inputClassName === "theme-inputtext-lg" ? "" : "small"}`}
        >
          {label}
        </Form.Label>
      ) : (
        ""
      )}
      <Editor
        value={data}
        onTextChange={handleChange}
        style={{ height: editorHeight }}
        headerTemplate={header}
      />
    </Form.Group>
  );
};

export default TextEditor;