import { Paginator } from "primereact/paginator";
import { FC, useState } from "react";
import {
  CUSTOM_PAGINATOR_VALUES,
  PAGINATOR_FIRST,
  PAGINATOR_ROWS_DEF,
} from "../../../constants/global";

export interface ICustomPaginator {
  data: string;
  onPageChange: any;
  tableRecords: any;
}

//Render Custom Pagination
const CustomPaginator: FC<ICustomPaginator> = ({
  data,
  onPageChange,
  tableRecords,
}) => {
  const [first, setFirst] = useState(PAGINATOR_FIRST);
  const [rows, setRows] = useState(PAGINATOR_ROWS_DEF);

  let totalRecords = data === undefined ? PAGINATOR_FIRST : data.length;
  const handlePageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    const newCurrentPage = Math.floor(event.first / event.rows) + 1;
    // Call the onPageChange prop to send the currentPage to the parent component
    onPageChange(newCurrentPage, event.rows);
  };
  const template2 = {
    layout:
      "RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink",
    CurrentPageReport: () => {
      return (
        <span className="current-page-report mx-xl-1 px-3 px-xl-4 text-center">

          {`${totalRecords === undefined && totalRecords < 1 ? PAGINATOR_FIRST : (first + 1)

            }-${first + totalRecords} of ${tableRecords === undefined || tableRecords === null ? totalRecords : tableRecords.totalrecord}`}

        </span>


      );
    },
  };

  return (
    <>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={
          tableRecords === null || tableRecords === undefined
            ? totalRecords
            : tableRecords.totalrecord
        }
        onPageChange={handlePageChange}
        rowsPerPageOptions={CUSTOM_PAGINATOR_VALUES}
        template={template2}
      />
    </>
  );
};

export default CustomPaginator;
