import { FC } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

interface IInfoTooltip {
  tooltipTitle: any;
  tooltipData: any;
  placement?: any;
  className?: any;
  onClick?: any;
  hideTooltip?: any;
  disableProp?: any;
}

//Info Tooltip Common Component
const InfoTooltip: FC<IInfoTooltip> = ({
  placement,
  tooltipData,
  className,
  tooltipTitle,
  onClick,
  hideTooltip,
  disableProp
}) => {
  const renderTooltip = (props: any): any => {
    if(!hideTooltip){
      return(<Tooltip>{props}</Tooltip>);
    }
    return(<></>)
  };
  return (
    <OverlayTrigger
      placement={placement}
      overlay={renderTooltip(tooltipData)}
      trigger={["hover", "hover"]}
    >
      <Button
        className={className}
        variant="link"
        onClick={onClick}
        disabled={disableProp}
      >
        {tooltipTitle}
      </Button>
    </OverlayTrigger>
  )
};

export default InfoTooltip;
