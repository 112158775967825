import { FC } from 'react';
import Form from 'react-bootstrap/Form';
import { InputText } from "primereact/inputtext";
interface IThemeInputText {
    controlId: string;
    label?: string;
    placeholder?: string;
    handleChange: any;
    handleBlur: any;
    errorsField: any;
    touched: any;
    value: any;
    inputClassName?: any;
    inputWrapClass?: any;
    inputIcon?: any;
    disabled?: boolean;
    labelTooltip?: any;
    inputIconClassName?: any;
    autoFocus?:boolean
}

//Input Type Text Reusable Component
const ThemeInputText: FC<IThemeInputText> = ({
    controlId,
    label,
    placeholder,
    handleChange,
    handleBlur,
    errorsField,
    touched,
    value,
    inputClassName,
    inputWrapClass,
    inputIcon,
    disabled,
    labelTooltip,
    inputIconClassName,
    autoFocus
}) => {
    return (
        <Form.Group controlId={controlId}>
            {label ?
                <Form.Label className={`mb-1 d-flex gap-1 ${inputClassName === 'theme-inputtext-lg' ? '' : 'small'}`}>
                    {label}
                    {labelTooltip || ''}
                </Form.Label>
                : ''
            }
            <div className={`position-relative w-100 ${inputWrapClass || ''}`}>
                {inputIcon ? <i className={inputIconClassName || ''}>{inputIcon}</i> : ''}
                <InputText
                    autoFocus={autoFocus}
                    id={controlId}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange(controlId)}
                    className={errorsField && touched ? `p-invalid is-invalid w-100 ${inputWrapClass || ''} ${inputClassName || ''}` : `w-100 ${inputWrapClass || ''} ${inputClassName || ''}`}
                    onBlur={(e)=>handleBlur(e)}
                    disabled={disabled}
                />
            </div>
            <Form.Control.Feedback type="invalid" className={errorsField && touched ? 'd-block' : 'd-none'}>
                {errorsField}
            </Form.Control.Feedback>

        </Form.Group>
    );
}

export default ThemeInputText