import moment from "moment";
import { FC } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { DATE_FORMAT } from "../../../constants/global";

interface IViewEventAlertModal {
  show?: boolean;
  handleClose?: any;
  modalData?: any
}

/**
 * Event Alert View Modal Component
 *
 * @param {{ rowData: any; show: any; handleClose: any; }} {
  rowData,
  show,
  handleClose,
}
 * @returns {*}
 */

const ViewEventAlertModal: FC<IViewEventAlertModal> = ({
  show,
  handleClose,
  modalData
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered={true}
      scrollable={true}
      className="theme-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h6" className="fw-semibold">
          View Event Alert
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="small">
        <Row className="g-3">
          <Col sm={6}>
            <div className="fw-bold">Title</div>
            {modalData?.event_title}
          </Col>
          <Col sm={6}>
            <div className="fw-bold">Event Type</div>
            {modalData?.event_types}
          </Col>
          <Col sm={6}>
            <div className="fw-bold">Date</div>
            {moment(modalData?.event_date).format(DATE_FORMAT)}
          </Col>
          <Col sm={6}>
            <div className="fw-bold">Time</div>
            {modalData?.event_time}
          </Col>
          <Col xs={12}>
            <div className="fw-bold">Location</div>
            {modalData?.event_location}
          </Col>
          <Col xs={12}>
            <div className="fw-bold">Description</div>
            {modalData?.event_description}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ViewEventAlertModal;