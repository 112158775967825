import { FC } from 'react';
import Form from 'react-bootstrap/Form';
import { InputTextarea } from 'primereact/inputtextarea';

interface IThemeInputTextarea {
    controlId: string;
    label?: string;
    placeholder?: string;
    handleChange: any;
    handleBlur: any;
    errorsField: any;
    touched: any;
    value: any;
    inputClassName?: any;
    inputWrapClass?: any;
    inputIcon?: any;
    disabled?: boolean;
    rows?: any;
}

//Input Type Textarea Reusable Component
const ThemeInputTextarea: FC<IThemeInputTextarea> = ({
    controlId,
    label,
    placeholder,
    handleChange,
    handleBlur,
    errorsField,
    touched,
    value,
    inputClassName,
    inputWrapClass,
    inputIcon,
    disabled,
    rows
}) => {
    return (
        <Form.Group controlId={controlId}>
            {label ?
                <Form.Label className={`mb-1 d-flex ${inputClassName === 'theme-inputtext-lg' ? '' : 'small'}`}>{label}</Form.Label>
                : ''
            }
            <div className={`position-relative w-100 ${inputWrapClass || ''}`}>
                {inputIcon ? <i>{inputIcon}</i> : ''}
                <InputTextarea
                    id={controlId}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange(controlId)}
                    className={errorsField && touched ? `p-invalid is-invalid w-100 d-block ${inputClassName || ''}` : `w-100 d-block ${inputClassName || ''}`}
                    onBlur={handleBlur(controlId)}
                    disabled={disabled}
                    rows={rows}
                />
            </div>
            <Form.Control.Feedback type="invalid" className={errorsField && touched ? 'd-block' : 'd-none'}>
                {errorsField}
            </Form.Control.Feedback>

        </Form.Group>
    );
}

export default ThemeInputTextarea