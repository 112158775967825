import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./datagrid.scss";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import CustomPaginatorNew from "./CustomPaginatorCustom";

export interface IDataGridCommon {
  columns: any;
  data: any;
  onPageChange?: any;
  onSort?: any;
  currentPage?: any;
  tableRecords?: any;
  HideTablePaginator?: boolean;
  rows?: any;
  sortOrderProp?:string; 
  sortFieldProp:string;
  multiSortMetaVariableProp?:any; 
}
interface ColumnInfo {
  field: string;
  order: number;
}

/**
 * Custom component for Data Grid used at all places
 * @date 9/22/2023 - 12:51:16 PM
 *
 * @param {{ columns: any; data: any; onSort: any; onPageChange: any; tableRecords: any; HideTablePaginator: any; }} {
  columns,
  data,
  onSort,
  onPageChange,
  tableRecords,
  HideTablePaginator
}
 * @returns {*}
 */
 
const DataGridCommonNew: React.FC<IDataGridCommon> = ({
  columns,
  data,
  onSort,
  onPageChange,
  tableRecords,
  HideTablePaginator,
  currentPage,
  rows,
  sortOrderProp,
  sortFieldProp,
  multiSortMetaVariableProp
}) => {
    const sortFieldPropData = sortFieldProp === "created_at" ? "":sortFieldProp;
    const sortOrderPropNumber = sortOrderProp === "DESC" ? -1: 1;
    const [sortOrder, setSortOrder] = useState(sortOrderProp);
    const multiSortMetaVariable:any = sortFieldProp === "created_at" ? []: multiSortMetaVariableProp;  
    const [multiSortMeta, setMultiSortMeta] = useState<any>(multiSortMetaVariable);
    const [sortField, setSortField] = useState<string>(sortFieldPropData); //sortFieldProp === "created_at" ? "":sortFieldProp
    const [first, setFirst] = useState(0);
    const [sortOrderNumber, setSortOrderNumber] = useState<any>(sortOrderPropNumber);
    const handlePageChange = (newPage: number, rows: any) => {
        onPageChange(newPage, rows); // Call the prop function to update currentPage in the parent component
    };
    // useEffect(()=>{
    //     const multiSortMetaVariable:any = sortFieldProp === "created_at" ? []: multiSortMetaVariableProp;  
    //     setMultiSortMeta(multiSortMetaVariable);
    // },[sortFieldProp])
    //Sort List using header
    const handleSort = (event: any) => {
      const sortOrder = event.sortOrder !== 1 ? "DESC" : "ASC";
      setSortOrder(sortOrder);
      setSortField(event.sortField);
      setSortOrderNumber(event.sortOrder)
      onSort(sortOrder, event.sortField);
      setFirst(0);
      // event.multiSortMeta.forEach((columnInfo: ColumnInfo) => {
      //     const { field, order } = columnInfo;
      //     const sortOrder = order !== 1 ? "DESC" : "ASC";
      //     setSortOrder(sortOrder);
      //     setSortField(field);
      //     setSortOrderNumber(order);
      //     //console.log("###### multiSortMetaVariableProp #######", field, order, multiSortMetaVariableProp, event.multiSortMeta, multiSortMeta)
      //       let newObj = multiSortMeta.map((element:any)=> {
      //           element.order=order;
      //           return element;
      //       })
      //       setMultiSortMeta(newObj);
      //       // Call onSort with the updated sortOrder and sortField values
      //       onSort(sortOrder, field);
      //   });
    };
  return (
    <div className="d-flex dataGridMain flex-column h-100 overflow-auto w-100">
      <DataTable
        value={data}
        stripedRows
        onSort={handleSort}
        // sortMode="multiple"
        // multiSortMeta={multiSortMeta}
        sortField={sortField} 
        sortOrder={sortOrderNumber}
        sortIcon={
          sortOrder === "DESC" ? (
            <FiArrowDown className="p-sortable-column-icon" size={17} />
          ) : (
            <FiArrowUp className="p-sortable-column-icon" size={17} />
          )
        }
        className="flex-grow-1 h-auto overflow-auto"
        scrollable={true}
        scrollHeight="flex"
      >
        {columns.map((col: any) => (
          <Column
            key={col.field}
            sortable={col.sortable}
            field={col.field}
            header={col.header}
            headerClassName={`fs-15 ${col.headerClassName}`}
            style={{ width: col.width, minWidth: col.width }}
            body={col.body}
            bodyClassName={col.bodyClassName}
          />
        ))}
      </DataTable>

      {/* Custom Pagination */}
      {HideTablePaginator ? ''
        : <CustomPaginatorNew
          data={data}
          onPageChange={handlePageChange}
          tableRecords={tableRecords}
          currentPageNo={currentPage}
          rowsProp={rows}
        />
      }
    </div>
  );
};

export default DataGridCommonNew;
