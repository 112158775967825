import { FC } from "react";
import { Button, Modal, Stack } from "react-bootstrap";

interface IConfirmQuestionnaireModal {
  show?: boolean;
  handleClose?: any;
  modalConfirmButtonHandler?: any;
}

const ConfirmQuestionnaireModal: FC<IConfirmQuestionnaireModal> = ({
  show,
  handleClose,
  modalConfirmButtonHandler
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered={true}
      scrollable={true}
      size="sm"
      className="theme-modal"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h6" className="fw-semibold">
          Confirmation
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="min-h-100 fs-14">
        Are you sure you want to send the questionnaire to Colombo & Hurd?
      </Modal.Body>
      <Modal.Footer className="py-2">
        <Stack
          direction="horizontal"
          gap={3}
          className="flex-wrap py-1"
        >
          <Button
            onClick={handleClose}
            variant="outline-primary"
            className="min-w-80"
          >
            No
          </Button>
          <Button
            onClick={modalConfirmButtonHandler}
            variant="primary"
            className="min-w-80"
          >
            Yes
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmQuestionnaireModal;