import * as yup from "yup";
import { VALIDATION_MESSAGES } from "../validationErrors";
import { EMAIL_REGEX } from "../../constants/global";

/**
 * LoginSchema for Validation
 * @type {*}
 */

export const LoginSchema: any = yup.object().shape({
  user_email: yup
    .string()
    .required(VALIDATION_MESSAGES.EMAIL_REQUIRED)
    .matches(EMAIL_REGEX, VALIDATION_MESSAGES.VALID_EMAIL),

  password: yup
    .string()
    .required(VALIDATION_MESSAGES.PASSWORD_REQUIRED)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      VALIDATION_MESSAGES.PASSWORD_INVALID
    ),

});
