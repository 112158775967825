import { Password } from 'primereact/password';
import { FC } from 'react';
import Form from 'react-bootstrap/Form';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
interface IThemeInputPassword {
    controlId: string;
    label: string;
    placeholder?: string;
    handleChange: any;
    handleBlur: any;
    errorsField: any;
    touched: any;
    value: any;
    toggleMask?: any;
    feedback?: any;
    inputClassName?: string;
}

//Input Type Password Reusable Component
const ThemeInputPassword: FC<IThemeInputPassword> = ({
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value, toggleMask, feedback, inputClassName
}) => {
    return (
        <Form.Group controlId={controlId}>
            <label className={`mb-1 d-flex form-label ${inputClassName === 'theme-inputtext-lg' ? '' : 'small'}`}>{label}</label>
            <div className="position-relative">
                <Password
                    id={controlId}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange(controlId)}
                    className={errorsField && touched ? `p-invalid is-invalid w-100 ${inputClassName ? inputClassName : ''}` : `w-100 ${inputClassName ? inputClassName : ''}`}
                    inputClassName="w-100"
                    onBlur={handleBlur(controlId)}
                    toggleMask={toggleMask}
                    feedback={feedback}
                    showIcon={<MdVisibility size={20} color="#999999" />}
                    hideIcon={<MdVisibilityOff size={20} color="#999999" />}
                />
            </div>
            <Form.Control.Feedback type="invalid" className={errorsField && touched ? 'd-block' : 'd-none'}>
                {errorsField}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default ThemeInputPassword