import { Route, Routes } from 'react-router-dom';
import { baseRoutes } from '../../routes/base-routes';
import ListNotes from './list/List';

export const Notes = () => {
    return (
        <Routes>
            {<Route path="/" element={<ListNotes />} />}
            {<Route path={baseRoutes.notes.path} element={<ListNotes />} />}
        </Routes>
    )
};