import React from 'react';
import ReactDOM from 'react-dom/client';
//Prime Theme
import "./assets/primereact-sass-theme/themes/material/material-light/compact/indigo/theme.scss";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from 'redux-persist/integration/react';
import { injectStore } from './store/common-api/axios';

injectStore(store);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <App />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();





