import * as yup from "yup";
import { VALIDATION_MESSAGES } from "../validationErrors";

/**
 * Reset Password Schema for Validation
 * @type {*}
 */

export const ResetPasswordSchema: any = yup.object().shape({
    password: yup
        .string()
        .required(VALIDATION_MESSAGES.PASSWORD_REQUIRED)
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
            VALIDATION_MESSAGES.PASSWORD_INVALID
        ),
    confirm_password: yup
        .string()
        .required(VALIDATION_MESSAGES.PASSWORD_REQUIRED)
        .oneOf([yup.ref("password"), ""], VALIDATION_MESSAGES.PASSWORD_MUST_MATCH),

});