import * as yup from "yup";

/**
 * Notes => Create Note Modal Schema for Validation
 * @date 1/3/2024 - 3:42:20 PM
 *
 * @type {*}
 */

export const notesSchema: any = yup.object().shape({
    note_content: yup
        .string()
        .required()
        .label("Note content"),
});