import React, { FC } from "react";
import { Dropdown } from "primereact/dropdown";
import Form from "react-bootstrap/Form";

export interface IThemeCustomSelect {
  controlId: string;
  label?: string;
  handleChange?: any;
  handleBlur?: any;
  errorsField?: any;
  touched?: any;
  value?: any;
  data?: any;
  placeHolder: string;
  disabled?: boolean;
  filter?: boolean;
  filterPlaceholder?: string;
  inputClassName?: any;
  handleFilter?:any
  id?:any
  showClear?:any
}

//Custom component for Select
const ThemeCustomDropdown: FC<IThemeCustomSelect> = ({
  controlId,
  label,
  handleChange,
  handleBlur,
  errorsField,
  touched,
  value,
  data,
  placeHolder,
  disabled,
  filter,
  filterPlaceholder,
  inputClassName,
  handleFilter,
  id,
  showClear
}) => {
  return (
  
    <Form.Group controlId={controlId}>
      {label ?
        <Form.Label className={`mb-1 d-flex ${inputClassName === 'theme-inputtext-lg' ? '' : 'small'}`}>{label}</Form.Label>
        : ''
      }
      <div className="position-relative">
        <Dropdown
        id={id}
          inputId={controlId}
          name={controlId}
          value={value}
          options={data}
          optionLabel="label"
          placeholder={placeHolder}
          className={errorsField && touched ? `p-invalid is-invalid w-100 ${inputClassName || ''}` : `w-100 ${inputClassName || ''}`}
          onChange={(e)=>{handleFilter(e,controlId)}}
          onBlur={handleBlur ? handleBlur(controlId) : ""}
          disabled={disabled}
          filter={filter}
          filterPlaceholder={filterPlaceholder}
          clearIcon={showClear}
        />
        <Form.Control.Feedback type="invalid" className={errorsField && touched ? 'd-block' : 'd-none'}>
          {errorsField}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
};

export default ThemeCustomDropdown;
