import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { regenerateOTPAction } from "../../store/reducer/auth";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/Loader";

const Timer = (email: any) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isLoading = useSelector((state: any) => state.auth.loading);
  const initialTime = 59;

  // Function to load time from localStorage or initialize to default
  const getStoredTime = () => {
    if (localStorage.getItem("stopTimer")) {
      const storedTime = "0";
      return storedTime !== null ? parseInt(storedTime, 10) : 0;
    } else {
      const storedTime = localStorage.getItem("timer");
      return storedTime !== null ? parseInt(storedTime, 10) : initialTime;
    }
  };
  const [seconds, setSeconds] = useState<any>(getStoredTime);

  useEffect(() => {
    // Save current time to localStorage
    localStorage.setItem("timer", seconds);

    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds: any) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(interval); // Clear interval on unmount
    }

    return () => {
      localStorage.removeItem("timer");
    }
  }, [seconds]);

  useEffect(() => {
    // Clear localStorage when the timer hits 0
    if (seconds === 0) {
      localStorage.removeItem("timer");
      localStorage.setItem("stopTimer", "true");
    } else {
      localStorage.removeItem("stopTimer");
    }
  }, [seconds]);

  // Function to call the API
  const resendOTP = () => {
    const payloadData = email;
    dispatch(regenerateOTPAction(payloadData)).then((res) => {
      if (res?.payload?.statusCode == 200) {
        setSeconds(initialTime); // Reset timer to initial time
        localStorage.removeItem("stopTimer"); // Reset stopTimer flag
      }
    });
  };

  const formatTime = () => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return remainingSeconds == 0 ? (
      <>
        Didn't received One Time Code?
        <Button
          variant="link"
          className="align-top text-decoration-underline py-0 px-1 text-light-emphasis border-0 fs-6"
          onClick={resendOTP}
        >
          Resend
        </Button>
      </>
    ) : (
      `Resend One Time Code after - ${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`
    );
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="mb-3">{formatTime()}</div>
    </>
  );
};

export default Timer;
