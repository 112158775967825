import { ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Stack } from 'react-bootstrap';
import { MdAddCircleOutline, MdLibraryBooks } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DeleteModal from '../../../components/common/DeleteModal';
import Loader from '../../../components/common/Loader';
import RenderAction from '../../../components/common/dataGrid/ActionCell';
import DataGridCommonNew from '../../../components/common/dataGrid/DataGridCommonCustom';
import { DEF_SORT_ORDER, DELETE_NOTE_CONTENT, DELETE_NOTE_TITLE, LIST_CRR_PAGE, PAGINATOR_ROWS_DEF, REACT_TABLE_ORDER, ROLES_DEFAULT_SORT_COLUMN, SUCCESS_STATUS } from '../../../constants/global';
import { deleteNotesAction, getNotesListAction, notesActions, viewNotesAction } from '../../../redux/notesModule/notesSlice';
import AddNotesModal from '../modals/AddNotesModal';
import ViewNotesModal from '../modals/ViewNotesModal';
import { FaInfoCircle } from 'react-icons/fa';

const ListNotes = () => {
    const isLoading = useSelector((state: any) => state.notesSlice.loading);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [deleteShow, setDeleteShow] = useState(false);
    const [addNotesModalShow, setAddNotesModalShow] = useState(false);
    const [viewNotesModalShow, setViewNotesModalShow] = useState(false);
    const [notesInfo, setNotesInfo] = useState(null);
    const [notesDelete, setNotesDelete] = useState({});
    const location = useLocation();
    const listObjData = useSelector((state: any) => state.notesSlice.listObj);
    const [currentPage, setCurrentPage] = useState(listObjData ? listObjData?.currentPageProp : LIST_CRR_PAGE);
    const [sortOrder, setSortOrder] = useState(listObjData ? listObjData?.sortOrderProp : DEF_SORT_ORDER);
    const [sortField, setSortField] = useState(listObjData ? listObjData?.sortFieldProp : ROLES_DEFAULT_SORT_COLUMN);
    const [rows, setRows] = useState(listObjData ? listObjData?.rowsProp : PAGINATOR_ROWS_DEF);
    let locationObj = location.state !== undefined || location.state !== null ? location.state : undefined;
    const notesTypeList = useSelector((state: any) => state.notesSlice.notes);
    const notesList = notesTypeList === null || notesTypeList === undefined ? [] : notesTypeList.records;

    useEffect(() => {
        let rolePayload: any = {};
        if (locationObj) {
            rolePayload = {
                sortOrder: listObjData.sortOrderProp,
                currentPage: listObjData.currentPageProp,
                rows: listObjData.rowsProp,
                sortField: listObjData.sortFieldProp,
            };
            setSortOrder(listObjData.sortOrderProp);
            setCurrentPage(listObjData.currentPageProp);
            setRows(listObjData.rowsProp);
            setSortField(listObjData.sortFieldProp);
        } else {
            rolePayload = {
                sortOrder: DEF_SORT_ORDER,
                currentPage: LIST_CRR_PAGE,
                rows: PAGINATOR_ROWS_DEF,
                sortField: ROLES_DEFAULT_SORT_COLUMN,
            };
            setSortOrder(DEF_SORT_ORDER);
            setCurrentPage(LIST_CRR_PAGE);
            setRows(PAGINATOR_ROWS_DEF);
            setSortField(ROLES_DEFAULT_SORT_COLUMN);
        }
        getNoteList(rolePayload);
        dispatch(notesActions.changeListDataObj(rolePayload));
    }, [])

    const getNoteList = (payload: any) => {
        dispatch(getNotesListAction(payload))
    };

    /**
     * Handle Sort Action
     *
     * @param {*} data
     */
    const onSort = (order: string, field: string) => {
        let sortOrder = order === REACT_TABLE_ORDER ? DEF_SORT_ORDER : order;
        let sortField = field;
        const rolePayload = {
            sortOrder,
            sortField,
            currentPage,
            rows
        }
        setSortOrder(sortOrder);
        setSortField(field);
        getNoteList(rolePayload);
        dispatch(notesActions.changeListDataObj(rolePayload));
    };

    /**
     * Handle Page Change Action
     *
     * @param {*} data
     */
    const handlePageChange = (newPage: number, rows: any) => {
        let currentPage = newPage;
        const rolePayload = {
            sortOrder,
            sortField,
            currentPage,
            rows
        }
        setCurrentPage(newPage);
        setRows(rows);
        getNoteList(rolePayload);
        dispatch(notesActions.changeListDataObj(rolePayload));
    };

    const viewNoteInfo = (noteId: any) => {
        setNotesInfo(null);
        const payload = {
            noteId
        }
        dispatch(viewNotesAction(payload)).then((data: any) => {
            if (data.payload.statusCode == 200) {
                setNotesInfo(data.payload.data);
            }
        }).catch((err: any) => {
            console.log(err);
        });
    }


    /**
    * Deleting Roles List
    *
    * @param {*} rowData
    * @returns {*}
    */

    const deleteNotes = (rowData: any) => {
        if (!rowData) return;
        const notes_id = rowData.notes_id;
        const deletePayload = {
            notes_id,
        };

        dispatch(deleteNotesAction(deletePayload)).then((data: any) => {
            const rolePayload = {
                sortOrder,
                sortField,
                currentPage,
                rows
            }
            if (data.payload.statusCode == 200) {
                setDeleteShow(false);
                getNoteList(rolePayload);
            }
        }).catch((err: any) => {
            console.log(err);
        });
    };


    // Data Table Start  
    //Action Template
    const actionCellTemplate = (rowData: any) => {
        return (
            <RenderAction
                controlId="documents"
                rowData={rowData}
                isViewPopupEnable={true}
                isEditPopupEnable={true}
                isDeleteEnable={true}
                handleViewPopupClick={handleViewPopupClick}
                handleEditPopupClick={handleEditPopupClick}
                handleDeleteClick={handleDeleteClick}
            />
        );
    };

    // Notes Click Handler
    const handleAddNotesClick = (rowData: any) => {
        setNotesInfo(null)
        setAddNotesModalShow(true);
    }

    const handleCloseAddNotesPopup = (rowData: any) => {
        const rolePayload = {
            sortOrder,
            sortField,
            currentPage,
            rows
        }
        setAddNotesModalShow(false);
        setNotesInfo(null);
        getNoteList(rolePayload);
    }

    const handleViewPopupClick = (rowData: any) => {
        viewNoteInfo(rowData.notes_id);
        setViewNotesModalShow(true)
    }

    const handleEditPopupClick = (rowData: any) => {
        viewNoteInfo(rowData.notes_id);
        setAddNotesModalShow(true)
    }

    const handleDeleteClick = (rowData: any) => {
        setDeleteShow(true)
        setNotesDelete(rowData);
    };

    // Table Columns
    const columns = [
        {
            field: "normal_notes_content",
            header: "Notes",
        },
        {
            field: "created_at_without_timezone",
            header: "Created Date",
            sortable: true,
            width: '140px',
        },
        {
            field: "updated_at_without_timezone",
            header: "Updated Date",
            sortable: true,
            width: "140px",
        },
        {
            field: "action",
            header: "Action",
            headerClassName: "theme-action-cell",
            width: "120px",
            body: actionCellTemplate,
        },
    ];

    return (
        <>
            <Loader isLoading={isLoading} classCustom={'theme-loader-z-index'} />
            <div className="d-flex flex-column pageContainer p-3 h-100 overflow-y-scroll">
                <div className="contentHeader p-1">
                    <Stack
                        direction="horizontal"
                        gap={2}
                        className="flex-wrap justify-content-between pb-2"
                    >
                        <h1 className="fw-semibold h4 mb-0">Notes</h1>
                    </Stack>
                </div>
                <div className="flex-grow-1 pageContent position-relative pt-4 d-flex flex-column">
                    <Card className="bg-white shadow-lg border-0 theme-card-cover flex-grow-1">
                        <div className="theme-card-header px-1">
                            <Stack
                                direction="horizontal"
                                gap={3}
                                className="flex-wrap px-3 pt-3 pb-2 align-items-start"
                            >
                                <h5 className="mb-0 position-relative fw-normal me-auto">
                                    <div className="align-items-center bg-secondary d-inline-flex h-60 justify-content-center position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                        <MdLibraryBooks size={32} />
                                    </div>
                                    Notes Listing
                                    <p className="mb-2 pt-1 opacity-75 fs-14">
                                        <FaInfoCircle className="me-1 text-secondary-emphasis small" />
                                        To be used for personal use (ONLY) and NOT intended to be shared with your Legal Team.</p>
                                </h5>
                                <Stack
                                    direction="horizontal"
                                    gap={3}
                                    className="flex-wrap ms-md-auto theme-filter-col-2 flex-sm-nowrap"
                                >
                                    <Button
                                        variant="primary"
                                        className="text-nowrap"
                                        onClick={handleAddNotesClick}
                                    >
                                        <MdAddCircleOutline size={17} className="me-2" />
                                        Add Note
                                    </Button>
                                </Stack>
                            </Stack>
                        </div>

                        <div className="px-20 pt-1 pb-4">
                            <DataGridCommonNew
                                columns={columns}
                                data={notesList}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                                onSort={onSort}
                                tableRecords={notesTypeList}
                                rows={rows}
                                sortOrderProp={sortOrder}
                                sortFieldProp={sortField}
                            />
                        </div>
                    </Card>
                </div>
            </div>

            {/* Add Notes Modal */}
            <AddNotesModal
                show={addNotesModalShow}
                handleClose={() => handleCloseAddNotesPopup(false)}
                notesData={notesInfo}
            />

            {/* View Notes Modal */}
            <ViewNotesModal
                show={viewNotesModalShow}
                handleClose={() => setViewNotesModalShow(false)}
                notesData={notesInfo}
            />

            {/* Delete Modal */}
            <DeleteModal
                show={deleteShow}
                handleClose={() => setDeleteShow(false)}
                handleDeleteClose={() => setDeleteShow(false)}
                deleteModalHeaderTitle={DELETE_NOTE_TITLE}
                deleteModalBodyContent={DELETE_NOTE_CONTENT}
                deleteRowData={notesDelete}
                customDeleteFunction={deleteNotes}
            />

        </>
    )
}

export default ListNotes