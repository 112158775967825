import { FC } from "react";
import { Button, Modal, Stack } from "react-bootstrap";

interface IDeleteModal {
  show?: boolean;
  handleClose?: any;
  deleteModalHeaderTitle?: any;
  deleteModalBodyContent?: string;
  handleDeleteClose?: any;
  customDeleteFunction?: Function;
  deleteRowData?: any;
}

//Confirm Delete Modal Reusable Component
const DeleteModal: FC<IDeleteModal> = ({
  show,
  handleClose,
  deleteModalHeaderTitle,
  deleteModalBodyContent,
  handleDeleteClose,
  customDeleteFunction,
  deleteRowData
}) => {
  const createMarkup = () => {
    return { __html: deleteModalBodyContent || "" };
  };

  const deleteRecordHandler = () => {
    if (customDeleteFunction) {
      customDeleteFunction(deleteRowData);
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      scrollable={true}
      size="sm"
      className="theme-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h6" className="fw-semibold">
          {deleteModalHeaderTitle || ""}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-3 min-h-100">
        <div className="small" dangerouslySetInnerHTML={createMarkup()} />
      </Modal.Body>

      <Modal.Footer className="py-2">
        <Stack direction="horizontal" gap={3} className="flex-wrap py-1">
          <Button
            variant="outline-primary"
            onClick={handleClose}
            className="min-w-80"
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            className="min-w-80"
            onClick={deleteRecordHandler}
          >
            Confirm
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
