import { FC } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { createMarkup } from "../../helpers/helperMethods";


interface ICommonAlertModalModal {
  show?: boolean;
  handleClose?: any;
  handleDeleteClose?: any;
  modalHeaderTitle?: any;
  modalBodyContent?: string;
}

const CommonAlertModal: FC<ICommonAlertModalModal> = ({
  show,
  handleClose,
  handleDeleteClose,
  modalHeaderTitle,
  modalBodyContent,
}) => {
 

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      scrollable={true}
      size="sm"
      className="theme-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h6" className="fw-semibold">
          {modalHeaderTitle || ""}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-3 min-h-100">
        <div className="small" dangerouslySetInnerHTML={createMarkup(modalBodyContent)} />
      </Modal.Body>

      <Modal.Footer className="py-2">
        <Stack direction="horizontal" gap={3} className="flex-wrap py-1">
          <Button
            variant="outline-primary"
            onClick={handleClose}
            className="min-w-80"
          >
            Close
          </Button>
          
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

export default CommonAlertModal;
