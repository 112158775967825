import { ThunkDispatch } from '@reduxjs/toolkit';
import { FC } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { questionairreActions } from '../../../redux/questionairreModule/questionairreSlice';

interface IDocuments {
    dashboardData?: any
}

const Documents: FC<IDocuments> = ({
    dashboardData
}) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const formatDashboardData = (data: any) => {
        const documentsData: any = [];
        const statuses = ['Approved', 'Uploaded', 'Pending'];
        statuses.forEach((status: any) => {
            if (data[status]) {
                documentsData.push({
                    id: status + 'Documents',
                    title: `${data[status].length} Document${data[status].length > 1 ? 's' : ''}`,
                    status: status,
                });
            }
        });
        return documentsData;
    }
    const documentsData = formatDashboardData(dashboardData?.documentRecord || {});


    return (
        <Card className="shadow-lg bg-white border-0 h-100">
            <Card.Header className="d-flex align-items-center flex-wrap bg-white p-3 border-0 gap-2">
                <Card.Title className="fw-bold mb-0 me-auto text-primary fs-20">Documents</Card.Title>
                <Link
                    to="/documents"
                    className="text-decoration-underline"
                    onClick={() => {
                        dispatch(questionairreActions.setCaseID({ caseID: dashboardData?.caseId }))
                    }}
                >
                    View All
                </Link>
            </Card.Header>
            <Card.Body className="p-3 pt-0">
                <ul className="list-unstyled">
                    {documentsData.map((dataItems: any, index: number) => {
                        const { id, title, status } = dataItems;
                        const isLastItem = index === documentsData.length - 1;
                        const classNotForLastChild = isLastItem ? '' : 'border-bottom pb-2 mb-2';

                        // Set color based on status
                        let statusColor = '';
                        switch (status) {
                            case 'Approved':
                                statusColor = 'dashboard-text-green';
                                break;
                            case 'Uploaded':
                                statusColor = 'dashboard-text-orange';
                                break;
                            case 'Pending':
                                statusColor = 'dashboard-text-gray';
                                break;
                            default:
                                statusColor = 'dashboard-text-red';
                        }

                        return (
                            <li key={id} className={`d-flex gap-2 ${classNotForLastChild}`}>
                                <div className="me-auto">{title}</div>
                                <div className={`text-end ${statusColor}`}>{status}</div>
                            </li>
                        )
                    })}
                    {documentsData.length <= 0 &&
                        <li className='small'>No results found</li>
                    }
                </ul>
            </Card.Body>
        </Card>
    )
}

export default Documents