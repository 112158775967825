import React, { FC } from "react";
import { MultiSelect } from 'primereact/multiselect'
import Form from "react-bootstrap/Form";
import { IS_MULTI_SELECT_FILTER, MAX_SELECT_LABEL_LEGTH } from "../../constants/global";

export interface IThemeCustomMultiSelect {
  controlId: string;
  label: string;
  handleChange?: any;
  handleBlur?: any;
  errorsField?: any;
  touched?: any;
  value?: any;
  data?: any;
  placeHolder: string;
  disabled?: boolean;
  inputClassName?: any;
  filterPlaceholder?: string;
  id?:any;
  handleFilter?:any,
  showClearProp?:any
}

/**
 * Custom component for MultiSelect
 *
 * @param {{ controlId: any; label: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; data: any; placeHolder: any; disabled: any; inputClassName: any; filterPlaceholder: any; handleFilter:any ; id:any}} {
  controlId,
  label,
  handleChange,
  handleBlur,
  errorsField,
  touched,
  value,
  data,
  placeHolder,
  disabled,
  inputClassName,
  filterPlaceholder
}
 * @returns
 */

const ThemeCustomMultiSelect: FC<IThemeCustomMultiSelect> = ({
  controlId,
  label,
  handleChange,
  handleBlur,
  errorsField,
  touched,
  value,
  data,
  placeHolder,
  disabled,
  inputClassName,
  filterPlaceholder,
  id,
  handleFilter,
  showClearProp
}) => {
  return (
    <Form.Group controlId={controlId}>
      {label ?
        <Form.Label className={`mb-1 d-flex ${inputClassName === 'theme-inputtext-lg' ? '' : 'small'} ${disabled ? 'opacity-50' : ''}`}>{label}</Form.Label>
        : ''
      }
      <div className="position-relative">
        <MultiSelect
        id={id}
          inputId={controlId}
          value={value}
          onChange={(e)=>{handleChange ?  handleChange(e) : handleFilter(e,controlId)}}
          onBlur={handleBlur ? handleBlur(controlId) : ""}
          options={data}
          optionLabel="label"
          placeholder={placeHolder}
          maxSelectedLabels={MAX_SELECT_LABEL_LEGTH}
          className={errorsField && touched ? `p-invalid is-invalid w-100 ${inputClassName ? inputClassName : ''}` : `w-100 ${inputClassName ? inputClassName : ''}`}
          filter={IS_MULTI_SELECT_FILTER}
          filterPlaceholder={filterPlaceholder}
          disabled={disabled}
          showClear={showClearProp}
        />

        <Form.Control.Feedback type="invalid" className={errorsField && touched ? 'd-block' : 'd-none'}>
          {errorsField}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
};

export default ThemeCustomMultiSelect;
