import moment from 'moment';
import { FC, useState } from 'react';
import { Card, Stack } from 'react-bootstrap';
import RenderAction from '../../../components/common/dataGrid/ActionCell';
import DataGridCommon from '../../../components/common/dataGrid/DataGridCommon';
import { DATE_FORMAT, HIDE_TABLE_PAGINATOR } from '../../../constants/global';
import ViewEventAlertModal from '../modals/ViewEventAlertModal';
import { Accordion, AccordionTab } from 'primereact/accordion';
interface IEventAlert {
    dashboardData?: any
}

const EventAlert: FC<IEventAlert> = ({
    dashboardData
}) => {
    const [viewEventAlertModalShow, setViewEventAlertModalShow] = useState(false);
    const [eventAlertRowData, setEventAlertRowData] = useState(null);

    // Data Table Start
    const actionCellTemplate = (rowData: any) => {
        return (
            <RenderAction
                controlId="event-alert"
                rowData={rowData}
                isViewPopupEnable={true}
                handleViewPopupClick={handleViewPopupClick}
            />
        );
    };

    const handleViewPopupClick = (rowData: any) => {
        setEventAlertRowData(rowData)
        setViewEventAlertModalShow(true)
    }

    // Event Title Column Template
    const eventTitleColTemplate = ((rowData: any) => {
        return <div className={`mw-100 text-truncate ${rowData?.showInBold && 'fw-bold'}`}>{rowData?.event_title}</div>
    });

    // Event Date Column Template
    const eventDateColTemplate = ((rowData: any) => {
        return <div className={`mw-100 text-truncate ${rowData?.showInBold && 'fw-bold'}`}>{moment(rowData?.event_date).format(DATE_FORMAT)}</div>
    });

    // Event Time Column Template
    const eventTimeColTemplate = ((rowData: any) => {
        return <div className={`mw-100 text-truncate ${rowData?.showInBold && 'fw-bold'}`}>{rowData?.event_time}</div>
    });

    // Event Location Column Template
    const eventLocationColTemplate = ((rowData: any) => {
        return <div className={`mw-100 text-truncate ${rowData?.showInBold && 'fw-bold'}`}>{rowData?.event_location}</div>
    });

    // Event Description Column Template
    const eventDescriptionColTemplate = ((rowData: any) => {
        return <div className={`mw-100 text-truncate ${rowData?.showInBold && 'fw-bold'}`}>{rowData?.event_description}</div>
    });

    // Table Columns
    const columns = [
        {
            field: "event_title",
            header: "Title",
            body: eventTitleColTemplate,
        },
        {
            field: "event_date",
            header: "Date",
            body: eventDateColTemplate,
        },
        {
            field: "event_time",
            header: "Time",
            body: eventTimeColTemplate,
        },
        {
            field: "event_location",
            header: "Location",
            body: eventLocationColTemplate,
        },
        {
            field: "event_description",
            header: "Description",
            body: eventDescriptionColTemplate,
        },
        {
            field: "action",
            header: "Action",
            headerClassName: "theme-action-cell theme-table-cell-center",
            bodyClassName: "theme-action-btns-center",
            width: "100px",
            body: actionCellTemplate,
        },
    ];

    return (
        <>
            <div className="theme-accordion-cover shadow-lg">
                <Accordion
                    multiple
                    activeIndex={[0]}
                >
                    <AccordionTab
                        headerTemplate={
                            <Stack
                                direction="horizontal"
                                gap={2}
                                className="flex-fill pe-3 mw-1"
                            >
                                <div className="d-inline-flex theme-accordion-title mw-1">
                                    <h5 className="text-primary fw-bold mb-0 mw-100 pe-1 text-truncate">Event Alert</h5>
                                </div>
                            </Stack>
                        }
                    >
                        <div className="theme-accordion-data">
                            <DataGridCommon
                                columns={columns}
                                data={dashboardData?.CaseEventsRecord}
                                HideTablePaginator={HIDE_TABLE_PAGINATOR}
                            />
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
            {/* View Event Alert Modal */}
            <ViewEventAlertModal
                show={viewEventAlertModalShow}
                handleClose={() => setViewEventAlertModalShow(false)}
                modalData={eventAlertRowData}
            />
        </>
    )
}

export default EventAlert