import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EndPoint from '../../api/endpoint';
import { axiosRequest } from '../common-api/axios';

const userInfo = {};
const initialAuthState = {
  isAuthenticated: false,
  userInfo: userInfo,
  loading: false,
  company: false,
};

export const setupAccountAction = createAsyncThunk(
  "authModule/setupAccountAction",
  async (value: any) => {
    const authData = {
      email: value,
    };
    try {
      const response = await axiosRequest.post(`${EndPoint.SET_ACCOUNT}`, authData);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const resetPasswordAction = createAsyncThunk(
  "authModule/resetPasswordAction",
  async (value: any) => {
    const authData = {
      token: value.token,
      password: value.password
    };
    try {
      const response = await axiosRequest.post(`${EndPoint.RESET_PASSWORD}`, authData);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const loginAction = createAsyncThunk(
  "authModule/loginAction",
  async (value: any) => {
    const authData = {
      email: value.email,
      password: value.password
    };
    try {
      const response = await axiosRequest.post(`${EndPoint.LOGIN_API}`, authData);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const OTPAction = createAsyncThunk(
  "authModule/OTPAction",
  async (value: any) => {   
    try {
      const response = await axiosRequest.post(`${EndPoint.OTP_API}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const regenerateOTPAction = createAsyncThunk(
  "authModule/regenerateOTPAction",
  async (value: any) => {   
    try {
      const response = await axiosRequest.post(`${EndPoint.REGENERATE_OTP_API}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const forgotPasswordAction = createAsyncThunk(
  "authModule/forgotPasswordAction",
  async (value: any) => {
    const authData = {
      email: value,
    };
    try {
      const response = await axiosRequest.post(`${EndPoint.FORGOT_PASSWORD}`, authData);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//Change Password
export const changePasswordAction = createAsyncThunk(
  "authModule/changePasswordAction",
  async (value: any) => {
    const authData = {
      token: value.token,
      current_password: value.current_password,
      new_password: value.new_password
    };
    try {
      const response = await axiosRequest.put(`${EndPoint.CHANGE_PASSWORD}`, authData);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//Change Password
export const logoutAction = createAsyncThunk(
  "authModule/logoutAction",
  async () => {
    try {
      const response = await axiosRequest.post(`${EndPoint.LOGOUT_API}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);


const authSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      state.isAuthenticated = true;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.userInfo = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setupAccountAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(setupAccountAction.fulfilled, (state, action) => {
        let respData = action.payload.data
        state.loading = false;
      })
      .addCase(setupAccountAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(resetPasswordAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPasswordAction.fulfilled, (state, action) => {
        let respData = action.payload.data
        state.loading = false;
      })
      .addCase(resetPasswordAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(loginAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        let respData = action.payload.data;
        if(respData?.access_token) {
          if (action?.payload?.statusCode === 200) {
            state.isAuthenticated = true;
          } else {
            state.isAuthenticated = false;
          }
          state.userInfo = respData;
        }
        
        state.company = action?.payload?.company
        state.loading = false;
        
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.loading = false;
        // state.isAuthenticated = false;
      })
      .addCase(OTPAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(OTPAction.fulfilled, (state, action) => {
        let respData = action.payload.data;
        state.loading = false;
        if (action?.payload?.statusCode === 200) {
          state.isAuthenticated = true;
        } else {
          state.isAuthenticated = false;
        }
        state.userInfo = respData;
      })
      .addCase(OTPAction.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(regenerateOTPAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(regenerateOTPAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(regenerateOTPAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(forgotPasswordAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPasswordAction.fulfilled, (state, action) => {
        let respData = action.payload.data
        state.loading = false;
      })
      .addCase(forgotPasswordAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(changePasswordAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePasswordAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(changePasswordAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(logoutAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.userInfo = {};
        localStorage.removeItem("timer");
        localStorage.removeItem("stopTimer");
      })
      .addCase(logoutAction.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.userInfo = {};
      })
  }
});

export const authActions = authSlice.actions;

export default authSlice.reducer;