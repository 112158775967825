import * as yup from "yup";
import { VALIDATION_MESSAGES } from "./validationErrors";

/**
 * Change Password Schema for Validation
 * @date 1/3/2024 - 4:25:26 PM
 *
 * @type {*}
 */

export const ChangePasswordSchema: any = yup.object().shape({
    current_password: yup
        .string()
        .required()
        .label('Current password')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
            VALIDATION_MESSAGES.PASSWORD_INVALID
        ),
    new_password: yup
        .string()
        .required()
        .label('New password')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
            VALIDATION_MESSAGES.PASSWORD_INVALID
        ),
    confirm_password: yup
        .string()
        .required()
        .label('Confirm password')
        .oneOf([yup.ref("new_password"), ""], VALIDATION_MESSAGES.PASSWORD_MUST_MATCH),

});
