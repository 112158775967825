import moment from "moment";

//Finding Duplicate Value in Array
export const DownloadExcelHelperMethod = (resp: any, fileName: string) => {

  let data = resp.payload.data ? resp.payload.data : null
  if (!data) {
    return
  }

  if (data !== null || data !== undefined) {
    let filedata = new Blob([data], { type: 'text/csv' });
    let csvURL = window.URL.createObjectURL(filedata);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${fileName}.csv`);
    tempLink.click();
  }

}

/**
 * Creating Sanitize Markup
 *
 * @param {*} arr
 * @param {string} html
 * @returns {*}
 */

export const createMarkup = (html: any) => {
  return { __html: html };
}


/**
 * Time Format for Mails
 *
 * @param {*} arr
 * @param {string} dateTimeString
 * @returns {*}
 */
export const formatDateTimeInEmails = (dateTimeString: any) => {
  const now = moment();
  const dateTime = moment(dateTimeString);
  if (now.isSame(dateTime, "day")) {
    return dateTime.fromNow(); // Format for today's date
  }
  return dateTime.format("MM/DD/YYYY");
};