import * as yup from "yup";
import { VALIDATION_MESSAGES } from "../validationErrors";
import { EMAIL_REGEX } from "../../constants/global";

/**
 * LoginSchema for Validation
 * @type {*}
 */

export const ForgotPasswordSchema: any = yup.object().shape({
  user_email: yup
    .string()
    .required(VALIDATION_MESSAGES.EMAIL_REQUIRED)
    .matches(EMAIL_REGEX, VALIDATION_MESSAGES.VALID_EMAIL),

});
