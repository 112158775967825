import { Route, Routes } from 'react-router-dom';
import { VIEW } from '../../constants/global';
import { baseRoutes } from '../../routes/base-routes';
import ListQuestionnaires from './list/List';
import ViewQuestionnaires from './view/View';

export const Questionnaires = () => {
    return (
        <Routes>
            {<Route path="/" element={<ListQuestionnaires />} />}
            {<Route path={baseRoutes.questionnaires.path} element={<ListQuestionnaires />} />}
            {<Route path={`${VIEW}/:caseId/:questionnaireid`} element={<ViewQuestionnaires />} />}
        </Routes>
    )
};