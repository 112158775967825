import { RouteObject, useRoutes } from "react-router-dom";
import { publicRoutes } from "./public";
import { companyProtectedRoutes, clientProtectedRoutes } from "./protected";
import CheckPermissionToPerformAction from "./protected-component/hasPermissionToPerformAction";
import { store } from "../store/index";
/**
 *
 * @returns Routes Elements
 */

const AppRoutes: any = () => {
  const { hasPermission } = CheckPermissionToPerformAction();

  // company_login key is true will indicate Company and if false it will indicate client
  const mainRoutes: RouteObject[] = store?.getState()?.auth?.userInfo?.company_login ? companyProtectedRoutes : clientProtectedRoutes;
  const element = useRoutes([...mainRoutes]);
  const elementPublic = useRoutes([...publicRoutes]);
  return (
    <>
      {hasPermission() ? element : null}
      {!hasPermission() ? elementPublic : null}
    </>
  );
}

export default AppRoutes;
