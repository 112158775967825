import { FC, useEffect, useState } from "react";
import { Paginator } from "primereact/paginator";
import {
  CUSTOM_PAGINATOR_VALUES,
  PAGINATOR_FIRST,
  PAGINATOR_ROWS_DEF,
} from "../../../constants/global";
import { Dropdown } from "primereact/dropdown";

export interface ICustomPaginator {
  data: string;
  onPageChange: any;
  tableRecords: any;
  currentPageNo:any;
  rowsProp:any;
}

/**
 * Render Custom Pagination 
 * @date 9/7/2023 - 1:23:46 PM
 *
 * @param {{ data: any; }} {
    data,

}
 * @returns {*}
 */

const CustomPaginatorNew: FC<ICustomPaginator> = ({
  data,
  onPageChange,
  tableRecords,
  currentPageNo,
  rowsProp
}) => {
  const [first, setFirst] = useState(currentPageNo ? (currentPageNo-1) * rowsProp : PAGINATOR_FIRST);
  const [rows, setRows] = useState(rowsProp);
  let totalRecords = data === undefined ? PAGINATOR_FIRST : data.length;
  useEffect(()=>{
    setFirst(currentPageNo ? (currentPageNo-1) * rowsProp : PAGINATOR_FIRST);
  },[currentPageNo])
  useEffect(()=>{
    setRows(rowsProp);
  },[rowsProp])
  const handlePageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    const newCurrentPage = Math.floor(event.first / event.rows) + 1;
    // Call the onPageChange prop to send the currentPage to the parent component
    onPageChange(newCurrentPage, event.rows);
  };
  const template2 = {
    layout:
      "RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink",
    CurrentPageReport: (options:any) => {
      return (
        <span className="current-page-report mx-xl-1 px-3 px-xl-4 text-center">

          {`${totalRecords === undefined && totalRecords < 1 ? PAGINATOR_FIRST : (((currentPageNo-1)*rowsProp)  + 1)

            }-${((currentPageNo-1)*rowsProp) + totalRecords} of ${tableRecords === undefined || tableRecords === null ? totalRecords : tableRecords.totalrecord}`}

        </span>
      );
    },
    PageLinks: (options:any) => {
      if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
          const className = `${options.className} 'p-disabled': true`;

          return <span className={className} style={{ userSelect: 'none' }}>...</span>;
      }
      
      options.currentPage=currentPageNo;
      const totalValue = tableRecords === undefined || tableRecords === null ? totalRecords : tableRecords.totalrecord;
      const totalPageValue = (totalValue/rowsProp) > 1 ? Math.ceil(totalValue/rowsProp):1;
      options.totalPages = totalPageValue;
      
      return (
          <button type="button" className={options.className} onClick={options.onClick}>
              {options.page + 1}
          </button>
      )
    },
    RowsPerPageDropdown: (options:any) => {

      return <Dropdown value={rowsProp} options={CUSTOM_PAGINATOR_VALUES} onChange={options.onChange} />;
    },
  };

  return (
    <>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={
          tableRecords === null || tableRecords === undefined
            ? totalRecords
            : tableRecords.totalrecord
        }
        onPageChange={handlePageChange}
        rowsPerPageOptions={CUSTOM_PAGINATOR_VALUES}
        template={template2}
       
      />
    </>
  );
};

export default CustomPaginatorNew;
