import { Route, Routes } from 'react-router-dom';
import { baseRoutes } from '../../routes/base-routes';
import ListDocuments from './list/List';

export const Documents = () => {
    return (
        <Routes>
            {<Route path="/" element={<ListDocuments />} />}
            {<Route path={baseRoutes.documents.path} element={<ListDocuments />} />}
        </Routes>
    )
};