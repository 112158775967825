import React from 'react'
import {useSelector} from "react-redux"
import {Navigate, useLocation} from "react-router-dom";

const ProtectedRouteComponent = ({children}:any) => {
    const user = useSelector((state:any) => state.auth);
    let location = useLocation();
    if(!user.isAuthenticated) {
        return <Navigate to="/login" state={{ from: location}} replace />
    }
    return children
};

export default ProtectedRouteComponent;