import { Checkbox } from "primereact/checkbox";
import React from "react";
import { Form } from "react-bootstrap";

interface ICustomCheckbox {
    controlId?: string;
    value?: any;
    status?: any;
    onCheck?: any;
    disabled?: any;
}

const CustomCheckbox: React.FC<ICustomCheckbox> = ({
    controlId,
    value,
    status,
    onCheck,
    disabled,

}) => {
    return (
        <Form.Group controlId={controlId} className="d-flex gap-2">
            <Checkbox
                inputId={controlId}
                onChange={onCheck}
                checked={status}
                value={value}
                name={controlId}
                disabled={disabled}
            />
            <label
                htmlFor={controlId}
                className={`form-label lh-sm mb-0 small ${disabled ? 'opacity-25 pe-none' : ''}`}
            >
                {value}
            </label>
        </Form.Group>
    );
};

export default CustomCheckbox;
