import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { documentActions, downloadDocumentClientAction, downloadDocumentReportAction, getDocDetialsByIDAction } from "../../../redux/documentModule/documentSlice";
import { DATE_FORMAT, HIDE_TABLE_PAGINATOR, POP_UP_CLOSE_2000 } from "../../../constants/global";
import RenderAction from "../../../components/common/dataGrid/ActionCell";
import DataGridCommon from "../../../components/common/dataGrid/DataGridCommon";
import InfoTooltip from "../../../components/common/InfoTooltip";

interface IViewDocsModal {
  show?: boolean;
  handleClose?: any;
  docRowData?: any;
  openDocView?: any;
  openDocViewSample?: any;
}

/**
 * Preview All Docs Modal Component
 *
 * @param {{ show: any; handleClose: any; }} {
  show,
  handleClose,
}
 * @returns {*}
 */

const ViewDocsModal: FC<IViewDocsModal> = ({
  show,
  handleClose,
  docRowData,
  openDocView,
  openDocViewSample,
}) => {

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [sampleData, setSampleData] = useState([]);
  const [uploadedData, setUploadedData] = useState([]);
  const caseID = useSelector((state: any) => state.documentSlice.caseID);

  useEffect(() => {
    if (!show) return;
    setSampleData([]);
    setUploadedData([]);
    dispatch(documentActions.startLoader(true));
    const masterPayload = {
      doc_id: docRowData?.id,
    };
    dispatch(getDocDetialsByIDAction(masterPayload)).then((data:any) => {
      let _data = data.payload.data;
      setSampleData(_data.checklist_item_detail?.sample_document_url);
      _data?.uploaded_documents_history?.forEach((el: any) => {
        const clientFullName = _data?.client_info?.middle_name ? _data?.client_info?.first_name + " " + _data?.client_info?.middle_name + " " + _data?.client_info?.last_name : _data?.client_info?.first_name + " " + _data?.client_info?.last_name;
        const uploaderName = el?.uploader_name ? el?.uploader_name?.toLowerCase() : "-";
        el.uploaded_by = el?.uploader_name !== 'By Client' ? uploaderName : clientFullName;
        // el.uploaded_at = moment(el?.date_time)?.format(DATE_FORMAT)
        el.uploaded_at = moment.utc(el?.date_time)?.format(DATE_FORMAT);
      });
      setUploadedData(_data.uploaded_documents_history);
      dispatch(documentActions.stopLoader(true));
    }).catch((err: any) => {
      console.log(err);
    });
  }, [show])

  const sNoCellTemplate = (docRowData: any, index: any) => {
    return <span className="min-w-20 d-inline-block text-center">{index?.rowIndex + 1}</span>
  }

  // Sample 
  const actionCellTemplateSample = (docRowData: any) => {
    return (
      <RenderAction
        controlId="case-management/view/1/documents"
        rowData={docRowData}
        isViewPopupEnable={true}
        isCopyPopupEnable={false}
        handleViewPopupClick={handleViewPopupClickSample}
        handleCopyPopupClick={handleCopyPopupClick}
        isDownloadEnable={true}
        handleDownloadClick={handleDownloadClickSample}
        disabledDownload={false}
      />
    );
  };
  // uploaded 
  const actionCellTemplate = (docRowData: any) => {
    return (
      <RenderAction
        controlId="case-management/view/1/documents"
        rowData={docRowData}
        isViewPopupEnable={true}
        isCopyPopupEnable={false}
        handleViewPopupClick={handleViewPopupClick}
        handleCopyPopupClick={handleCopyPopupClick}
        isDownloadEnable={true}
        handleDownloadClick={handleDownloadClick}
        disabledDownload={false}
      />
    );
  };


  const handleDownloadClickSample = (rowData: any) => {
    dispatch(documentActions.startLoader(true));
    const masterPayload = {
      id: rowData?.id,
    };
    
    dispatch(downloadDocumentReportAction(masterPayload))
      .then((data) => {
        dispatch(documentActions.stopLoader(true));
        const fileUrl = data?.payload?.data?.file_url;
        if (fileUrl) {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = fileUrl;
          a.download = `${rowData?.fileName}`; // You can set the filename here
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(fileUrl);
          document.body.removeChild(a);
        }

      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(documentActions.stopLoader(true));
      });
    
  };
    //Handle Download uploaded new api
    const handleDownloadClick = (rowData: any) => {
      dispatch(documentActions.startLoader(true));
      const masterPayload = {
        id: rowData?.id,
      };
      const payload: any = {
        file_id: docRowData?.id,
        case_id: caseID,
        document_upload_data: true,
        form_upload_data: false,
        form_id: rowData?.id,
      }
      // dispatch(downloadDocumentReportAction(masterPayload))
      dispatch(downloadDocumentClientAction(payload))
        .then((data) => {
          dispatch(documentActions.stopLoader(true));
          const fileUrl = data?.payload?.data?.file_url;
          if (fileUrl) {
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = fileUrl;
            a.download = `${rowData?.fileName}`; // You can set the filename here
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(fileUrl);
            document.body.removeChild(a);
          }
  
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(documentActions.stopLoader(true));
        });
      
    };

  const handleCopyPopupClick = (docRowData: any) => {
    const tempInput = document.createElement('input')
    tempInput.value = docRowData.fileUrl
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    const newLocal = "File URL Copied";
    toast.success(newLocal, { autoClose: POP_UP_CLOSE_2000 })
  }

  const handleViewPopupClick = (docRowData: any) => {
    // let ext = docRowData.fileName.split(".")[1]
    // if (['doc', 'docx', 'pptx', 'xlsx', 'xls', 'txt'].find((i) => i === ext)) {
    //   window.open(docRowData.fileUrl, "_blank");
    //   return;
    // }
    openDocView(docRowData);
    handleClose();
  }
  
  const handleViewPopupClickSample = (docRowData: any) => {
    openDocViewSample(docRowData);
    handleClose();
  }

  const showDocumentNameInTooltip = (rowData: any) => {
    return <InfoTooltip
      placement="auto-end"
      className="p-0 text-body text-truncate mw-100"
      tooltipTitle={rowData.fileName ?? ""}
      tooltipData={`${rowData.fileName ?? ""}`}
    />
  }
  
  // Table Columns
  const columns = [
    {
      field: "s_no",
      header: "No.",
      body: sNoCellTemplate,
      headerClassName: "text-wrap",
      width: '50px',
    },
    {
      field: "fileName",
      header: "Document Name",
      body: showDocumentNameInTooltip,
    },
    {
      field: "action",
      header: "Action",
      headerClassName: "theme-action-cell",
      width: "100px",
      body: actionCellTemplateSample,
    },
  ];

  const columns_uploaded = [
    {
      field: "s_no",
      header: "No.",
      body: sNoCellTemplate,
      headerClassName: "text-wrap",
      width: '50px',
    },
    {
      field: "fileName",
      header: "Document Name",
      body: showDocumentNameInTooltip,
    },
    {
      field: "uploaded_by",
      header: "Uploaded By",
      body: (rowData: any) => { return <span className="min-w-20 d-inline-block text-center text-capitalize">{rowData.uploaded_by?.toLowerCase()}</span> }
    },
    {
      field: "uploaded_at",
      header: "Uploaded On",
    },
    {
      field: "action",
      header: "Action",
      headerClassName: "theme-action-cell",
      width: "100px",
      body: actionCellTemplate,
    },
  ];

  const applicantNames = () => {
    const applicantType = docRowData?.derivates_info?.is_co_applicant ? "Co-Applicant" : "Derivative";
    const clientFullName = docRowData?.client_info?.middle_name ? docRowData?.client_info?.first_name + " " + docRowData?.client_info?.middle_name + " " + docRowData?.client_info?.last_name : docRowData?.client_info?.first_name + " " + docRowData?.client_info?.last_name;
    const applicantNames = docRowData?.derivates_info ? docRowData?.derivates_info?.case_relative_info?.full_name + " (" + applicantType + ")" : clientFullName + " (Main Applicant)";
    return <span className="min-w-20 d-inline-block text-center text-capitalize">{applicantNames.toLowerCase()}</span>;
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={true}
        scrollable={true}
        size="lg"
        className="theme-modal"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6" className="fw-semibold">
            View Document - {docRowData.document_name} / {applicantNames()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="fw-semibold text-primary mb-3">Sample Documents</h6>
          <div className="theme-modal-table pb-3">
            <DataGridCommon
              columns={columns}
              data={sampleData}
              HideTablePaginator={HIDE_TABLE_PAGINATOR}
            />
          </div>

          <h6 className="fw-semibold text-primary mb-3">Uploaded Documents</h6>
          <div className="theme-modal-table pb-3">
            <DataGridCommon
              columns={columns_uploaded}
              data={uploadedData}
              HideTablePaginator={HIDE_TABLE_PAGINATOR}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewDocsModal;
