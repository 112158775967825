import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EndPoint from '../../api/endpoint';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, PAGINATOR_ROWS_DEF, ROLES_DEFAULT_SORT_COLUMN } from '../../constants/global';
import { axiosRequest } from '../../store/common-api/axios';

const initialState = {
    loading: false,
    caseID: "",
};

// GETTING ALL DASHBOARD DATA
export const dashboardCombineAction = createAsyncThunk(
    "dashboard/dashboardCombineAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.post(`${EndPoint.DASHBOARD_API}`, value);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

const dashboardSlice = createSlice({
    name: 'Dashboard',
    initialState: initialState,
    reducers: {
        setCaseID(state, actions) {
            state.caseID = actions.payload.caseID;
        },
        startLoader(state, actions) {
            state.loading = true;
        },
        stopLoader(state, actions) {
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(dashboardCombineAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(dashboardCombineAction.fulfilled, (state, action) => {
                state.loading = false;
                state.caseID = action.payload.caseId;
            })
            .addCase(dashboardCombineAction.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const dashboarActions = dashboardSlice.actions;
export default dashboardSlice.reducer;