import { FC } from "react";
import { Button, Modal, Stack } from "react-bootstrap";

interface IValidationModal {
  show?: boolean;
  handleClose?: any;
  bodyContent?: string;
  isSection?: boolean
  validationData?: []
  proceedPage?: any;
  proceedSection?: any
  sectionData?: any
}

/**
 * Validation Modal Component
 *
 * @param {{ bodyContent: any; show: any; handleClose: any; }} {
show,
handleClose,
bodyContent,
}
 * @returns {*}
 */

const ValidationModal: FC<IValidationModal> = ({
  show,
  handleClose,
  bodyContent,
  isSection,
  validationData,
  proceedPage,
  proceedSection,
  sectionData
}) => {


  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered={true}
      scrollable={true}
      size="sm"
      className="theme-modal"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h6" className="fw-semibold">
          Validation
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="min-h-100 d-flex flex-column gap-3">
        {!isSection &&
          <p className="mb-0 fs-14">You must complete all required fields before submitting the questionnaire to Colombo & Hurd:</p>
        }
        {validationData?.map((item: any) => (
          item.messages.length > 0 ?
            <div className="fs-14" key={item.id}>
              <h6 className="fw-semibold fs-14 mb-1">
                {isSection ?
                  <>
                    {item.messages.length > 0 ?
                      <span className="fw-normal">Please complete all required fields in this section:</span> : item.title}
                  </> : item.title}
              </h6>

              <ul className="list-style-position-inside lh-lg">
                {item.messages.map((message: any) => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
              {isSection &&
                item.messages.length > 0 && <div className="pt-2">You may save your changes but please remember to complete all mandatory fields before submitting the questionnaire to Colombo & Hurd.</div>
              }
            </div> : ""
        ))}

      </Modal.Body>
      <Modal.Footer className="py-2">
        <Stack
          direction="horizontal"
          gap={3}
          className="flex-wrap py-1"
        >
          {isSection ?
            <>
              <Button
                onClick={handleClose}
                variant="outline-primary"
                className="min-w-60"
              >
                Continue Editing
              </Button>
              <Button
                onClick={() => proceedSection(sectionData)}
                variant="primary"
                className="min-w-60"
              >
                Save Changes
              </Button>
            </> :
            <Button
              onClick={handleClose}
              variant="primary"
              className="min-w-60"
            >
              Close
            </Button>}
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

export default ValidationModal;
