import React, { FC } from "react";
import { Button, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { MdAddCircleOutline, MdDelete, MdEdit, MdRefresh, MdRemoveRedEye } from "react-icons/md";
import { SvgIcons } from "../SvgIcons";

export interface IRenderActionCustom {
  controlId: string;
  redirectID?: string;
  isViewEnable?: boolean;
  disabledView?: boolean;
  isEditEnable?: boolean;
  disabledEdit?: boolean;
  isDeleteEnable?: boolean;
  handleDeleteClick?: any;
  disabledDelete?: boolean;
  rowData?: any;
  isMappingEnable?: boolean;
  disabledMapping?: boolean;
  goToMapping?: any;
  isAddMappingEnable?: boolean;
  goToAddMapping?: any;
  disabledAddMapping?: boolean;
  isCustomEdit?: any;
  isCustomView?: any;
  isCustomDelete?: any;
  isResetPopupEnable?: any
  handleResetPopupClick?: any
  disabledResetPopup?: any
}

const renderTooltip = (props: any) => <Tooltip id={props}>{props}</Tooltip>;

/**
 * Render Action Functions For Data Grid
 *
 * @param {{ controlId: any; redirectID: any; isViewEnable: any; disabledView: any; isEditEnable: any; disabledEdit: any; isDeleteEnable: any; handleDeleteClick: any; disabledDelete: any; rowData: any; isMappingEnable: any; ... 7 more ...; isCustomDelete: any; }} {
  controlId,
  redirectID,
  isViewEnable,
  disabledView,
  isEditEnable,
  disabledEdit,
  isDeleteEnable,
  handleDeleteClick,
  disabledDelete,
  rowData,
  isMappingEnable,
  disabledMapping,
  goToMapping,
  isAddMappingEnable,
  goToAddMapping,
  disabledAddMapping,
  isCustomEdit,
  isCustomView,
  isCustomDelete
}
 * @returns
 */
const RenderActionCustom: FC<IRenderActionCustom> = ({
  controlId,
  redirectID,
  isViewEnable,
  disabledView,
  isEditEnable,
  disabledEdit,
  isDeleteEnable,
  handleDeleteClick,
  disabledDelete,
  rowData,
  isMappingEnable,
  disabledMapping,
  goToMapping,
  isAddMappingEnable,
  goToAddMapping,
  disabledAddMapping,
  isCustomEdit,
  isCustomView,
  isCustomDelete,
  isResetPopupEnable,
  handleResetPopupClick,
  disabledResetPopup
}) => {

  return (
    <>
      <Stack direction="horizontal" className="theme-action-btns">
        {isViewEnable ? (
          <OverlayTrigger placement="top" overlay={renderTooltip("View")}>
            <Button
              className="p-1 lh-1 fs-5 theme-view-btn"
              variant="link"
              onClick={(e) => isCustomView(rowData)}
              disabled={disabledView}
            >
              <MdRemoveRedEye />
            </Button>
          </OverlayTrigger>
        ) : (
          ""
        )}

        {isEditEnable ? (
          <OverlayTrigger placement="top" overlay={renderTooltip("Edit")}>
            <Button
              className="p-1 lh-1 fs-5 theme-edit-btn"
              variant="link"
              onClick={(e) => isCustomEdit(rowData)}
              disabled={disabledEdit}
            >
              <MdEdit />
            </Button>
          </OverlayTrigger>
        ) : (
          ""
        )}

        {isResetPopupEnable ? (
          <OverlayTrigger placement="top" overlay={renderTooltip("Resend")} trigger={["hover", "hover"]}>
            <Button
              className="p-1 lh-1 fs-5 theme-reset-popup-btn"
              variant="link"
              onClick={() => {
                handleResetPopupClick(rowData);
              }}
              disabled={disabledResetPopup}
            >
              <MdRefresh size={18} className="theme-flip-x" />
            </Button>
          </OverlayTrigger>
        ) : (
          ""
        )}
        
        {isDeleteEnable ? (
          <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} trigger={["hover","hover"]}>
            <Button
              className="p-1 lh-1 fs-5 theme-delete-btn"
              variant="link"
              onClick={(e) => {
                isCustomDelete(rowData);
              }}
              disabled={disabledDelete}
            >
              <MdDelete />
            </Button>
          </OverlayTrigger>
        ) : (
          ""
        )}

        {isMappingEnable ? (
          <OverlayTrigger placement="top" overlay={renderTooltip("Form Mapping")}>
            <Button
              className="p-1 lh-1 fs-5 theme-mapping-btn"
              variant="link"
              onClick={goToMapping}
              disabled={disabledMapping}
            >
              {SvgIcons.fileShareIcon}
            </Button>
          </OverlayTrigger>
        ) : (
          ""
        )}

        {isAddMappingEnable ? (
          
            <Button
              className="theme-add-mapping-btn py-1 px-2"
              variant="primary"
              onClick={goToAddMapping}
              disabled={disabledAddMapping}
            >

              <MdAddCircleOutline size={17} className="me-2" />
                        Add New Mapping
            </Button>
          
                    
        ) : (
          ""
        )}

      </Stack>
    </>
  );
};

export default RenderActionCustom;
