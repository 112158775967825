import { Card, Image, Stack } from "react-bootstrap";
import comingSoonImage from "../../assets/images/coming-soon.svg";

const DashboardCompany = () => {
  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      {/* Header */}
      <Stack
        direction="horizontal"
        gap={2}
        className="flex-wrap mb-2"
      >
        <h1 className="fs-4 fw-semibold mb-0 me-auto">Dashboard</h1>
      </Stack>
      <Card className="shadow-lg bg-white border-0 flex-grow-1 d-flex flex-column">
        <div className="m-auto text-center">
          <div>
            <Image
              className="img-fluid"
              src={comingSoonImage}
              alt="Coming Soon Page Image"
              width={421}
              height={236}
            />
          </div>
          <h2 className="display-6 fw-bold mt-4 mb-0 text-primary">Coming Soon</h2>
        </div>
      </Card>
    </div>    
  );
};

export default DashboardCompany;
