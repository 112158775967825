
import { useSelector } from "react-redux";

const CheckPermissionToPerformAction = () => {
    const user = useSelector((state: any) => state.auth);
    const hasPermission = () => {
        if (!user.isAuthenticated) {
            return false;
        } else {
            return true;
        }
    }
    return { hasPermission };
};

export default CheckPermissionToPerformAction;

